.main-app {
    text-align: center;
    margin: 100px;
}

.cta-button {
    padding: 15px;
    margin: 10px;
    border: none;
    border-radius: 12px;
    min-width: 250px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.init-withdraw-function {
    background-color: blue;
}

.init-withdraw-function:disabled {
    background-color: #cccccc;
    color: #eeeeee;
}

.run-withdraw-button {
    background: rgb(32, 129, 226);
}

.run-withdraw-button:disabled {
    background-color: #cccccc;
    color: #eeeeee;
}

/* .mint-nft-button {
    background: orange;
} */